/** @jsxImportSource theme-ui */

import { graphql, HeadFC, Link } from "gatsby";
import type { PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import DOMPurify from "isomorphic-dompurify";
import { BaseStyles, ThemeUICSSObject } from "theme-ui";
import ExternalLink from "../components/external-link";
import Global from "../components/global";
import { Header } from "../components/header";
import Main from "../components/main";
import RecentList from "../components/recent-list";
import Seo from "../components/seo";
import Wrapper from "../components/wrapper";
import { createMyDate, createTime, MyDate } from "../utils/datetimeutils";
import { getImageWrapper } from "../utils/imageutils";

type SdataNodeWithObjects = {
  node: {
    name?: string | null;
    namePrefix?: string | null;
    nameSuffix?: string | null;
    canceled?: string | null;
    locationName?: string | null;
    locationSubName?: string | null;
    program?: string | null;
    cast?: string | null;
    content?: string | null;
    note?: string | null;
    sources?: string | null;

    dateObj?: MyDate;
    endDateObj?: MyDate;
    openTimeObj?: Date;
    startTimeObj?: Date;
    endTimeObj?: Date;
  };
};

interface VisitListEntry {
  text: string;
  url?: string;
}

const SITE_TITLE = "獅子舞大会の一覧";

export const Head: HeadFC<Queries.ShishimaiTaikaiPostQuery> = ({ data }) => {
  var meta = data.googleSmetaSheet;
  var title = `${meta?.namePrefix || ""}${meta?.nameOfficial || ""}の日程`;

  return <Seo title={title} siteTitleTemplate={`%s – ${SITE_TITLE}`} article={true} isShishimai={true} />;
};

const ShishimaiTaikaiPost = ({ data }: PageProps<Queries.ShishimaiTaikaiPostQuery>) => {
  // TODO: overwrite with default values from meta sheet

  var meta = data.googleSmetaSheet;
  var title = `${meta?.namePrefix || ""}${meta?.nameOfficial || ""}`;
  var lastModifiedObj = new Date(meta?.lastModified ?? "");
  var lastModified = `${lastModifiedObj.getFullYear()}年${lastModifiedObj.getMonth() + 1}月${lastModifiedObj.getDate()}日 `
    + `${String(lastModifiedObj.getHours()).padStart(2, "0")}:${String(lastModifiedObj.getMinutes()).padStart(2, "0")}`;
  const note = meta?.noteDefault;
  var visitList: VisitListEntry[] = [];
  if (meta?.visitList) {
    visitList = meta.visitList.split("\n").map(v => {
      const s = v.split("http"); // should work for both http and https
      if (s.length === 2) {
        const text = s[0];
        const url = `http${s[1]}`;
        return {
          text: text,
          url: url,
        };
      } else {
        return {
          text: v,
        };
      }
    });
  }

  const sdataWithObjects: SdataNodeWithObjects[] = data.allGoogleSdataSheet.edges.map(({ node }) => {
    const s: SdataNodeWithObjects = {
      node: node,
    };
    s.node.dateObj = createMyDate(node.date, node.isFest);
    s.node.endDateObj = createMyDate(node.endDate, node.isEndDateFest);
    s.node.openTimeObj = createTime(node.openTime);
    s.node.startTimeObj = createTime(node.startTime);
    s.node.endTimeObj = createTime(node.endTime);
    return s;
  });

  const sanitizedDescription = DOMPurify.sanitize(meta?.descriptionDefault ?? "");
  const imageData = getImageWrapper(data.file);

  return (
    <>
      <Global />
      <Header siteTitle={SITE_TITLE} />
      <Main>
        <Wrapper>
          <BaseStyles>
            <h1>{title}{meta?.nameSuffix && <span sx={titleSuffix}>{meta.nameSuffix}</span>}</h1>
            <div sx={lastMod}>{"最終更新日時：" + lastModified}</div>
            {sanitizedDescription
              ? (
                <>
                  <h2>概要</h2>
                  {imageData
                    ? (
                      <>
                        <div>
                          <GatsbyImage sx={imageAtRight} image={imageData} alt={title} />
                          <div sx={descriptionStyle} dangerouslySetInnerHTML={{ __html: sanitizedDescription }}></div>
                          <GatsbyImage sx={imageAtBottom} image={imageData} alt={title} />
                          <div style={{ clear: "both" }}></div>
                        </div>
                      </>
                    )
                    : <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }}></div>}
                </>
              )
              : ""}
            <h2>直近の開催日程</h2>
            <div sx={{ marginTop: "1.5rem" }}>
              <RecentList data={sdataWithObjects} locationsData={data.allGoogleSlocationSheet.edges} />
            </div>
            {note
              ? (
                <>
                  <h2>備考</h2>
                  <div>{note}</div>
                </>
              )
              : ""}
            {visitList.length > 0
              ? (
                <>
                  <h2>過去の見学メモ</h2>
                  <ul sx={visitUl}>
                    {visitList.map(v => {
                      if (v.url) {
                        const url = migrateVisitUrl(v.url);
                        if (url.startsWith("http")) {
                          // external site
                          return (
                            <li key={url}>
                              <ExternalLink url={url}>{v.text}</ExternalLink>
                            </li>
                          );
                        } else {
                          return (
                            <li key={url}>
                              <Link to={url}>{v.text}</Link>
                            </li>
                          );
                        }
                      } else {
                        return <li key={v.text}>{v.text}</li>;
                      }
                    })}
                  </ul>
                </>
              )
              : ""}
            <div sx={{ marginTop: "2.0rem", marginBottom: "2.0rem" }}>
              ※ 悪天候などの理由で予定が変更や中止になることもあります。お出かけの際は主催者の情報をお確かめ下さい。<br />
              古いリンクはリンク切れになっていることがありますがご了承ください。
            </div>
            <Link to="/shishimai/">獅子舞大会のページに戻る</Link>
          </BaseStyles>
        </Wrapper>
      </Main>
    </>
  );
};

export default ShishimaiTaikaiPost;

const titleSuffix = {
  fontSize: "80%",
};

const lastMod: ThemeUICSSObject = {
  fontSize: "80%",
  marginBottom: "2.0rem",

  "@media screen and (min-width: 601px)": {
    textAlign: "right",
  },
};

const imageAtRight: ThemeUICSSObject = {
  "@media screen and (min-width: 601px)": {
    marginLeft: "2.0rem",
    float: "right",
  },
  "@media screen and (max-width: 600px)": {
    display: "none",
  },
};

const imageAtBottom = {
  "@media screen and (min-width: 601px)": {
    display: "none",
  },
  "@media screen and (max-width: 600px)": {
    display: "block",
    margin: "0 auto 2.0rem auto",
    width: "80%",
  },
};

const descriptionStyle = {
  "@media screen and (min-width: 601px)": {
    marginBottom: "2.0rem",
  },
  "@media screen and (max-width: 600px)": {
    marginBottom: "1.0rem",
  },
};

const visitUl = {
  listStyleType: "disc",

  "li": {
    marginLeft: "1.5rem",
    marginTop: "0.3rem",
  },
};

function migrateVisitUrl(origUrl: string): string {
  if (origUrl.indexOf("minamishinshu_h22") > 0) {
    return "/blog/minamishinshu_h22/";
  } else if (origUrl.indexOf("shishishibai_h27") > 0) {
    return "/blog/shishishibai_h27/";
  } else {
    return origUrl;
  }
}

export const query = graphql`
  query ShishimaiTaikaiPost($taikaiId: String!, $imageFilePath: String) {
    googleSmetaSheet(taikaiId: {eq: $taikaiId}) {
      taikaiId
      namePrefix
      nameOfficial
      nameSuffix
      myNameSuffixDefault
      descriptionDefault
      images
      noteDefault
      visitList
      lastModified
    }
    allGoogleSdataSheet(filter: {taikaiId: {eq: $taikaiId}}) {
      edges {
        node {
          taikaiId
          name
          namePrefix
          nameSuffix
          canceled
          date
          isFest
          endDate
          isEndDateFest
          openTime
          startTime
          endTime
          locationName
          locationSubName
          program
          cast
          content
          note
          sources
        }
      }
    }
    allGoogleSlocationSheet {
      edges {
        node {
          locationName
          searchName
          locationAddress
        }
      }
    }
    file(relativePath: {eq: $imageFilePath}) {
      relativePath
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          placeholder: BLURRED
          height: 240
        )
      }
    }
  }
`;
